html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0px;
  height: 100%;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.app-logo {
  height: 30vmin;
  pointer-events: none;
}

.small-logo {
  height: 20vmin;
  pointer-events: none;
}

.app-light {
  background-color: #f7f7f7;
  color: #4e4e4e;
}

.app-light .app-link {
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.app-light .app-link:visited {
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.app-light .app-link a {
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.app-blue {
  background-color: #4572cc;
  color: white;
}

.app-blue .app-link {
  color: white;
  cursor: pointer;
}

.app-blue .app-link:visited {
  color: white;
  cursor: pointer;
}

.app-blue .app-link a {
  color: white;
  cursor: pointer;
}

.app-blue label {
  color: whitesmoke;
}

.app-blue .error-color {
  color: whitesmoke;
}

.spin {
  animation-duration: 1s;
  animation-name: app-logo-spin;
}

.no-pointer {
  pointer-events: none;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.larger-title {
  display: block;
  font-size: 1.1em;
  font-weight: bold;
  padding: 8px;
}

.half-size-title {
  display: block;
  font-size: .5em;
  padding: 0;
}

.font-14px {
  font-size: 14px;
}

.medium-font {
  font-size: .7em;
}

.smallest-font {
  font-weight: normal;
  font-size: 12px;
}

.bolder {
  font-weight: bolder;
}

.ul-numbered {
  list-style-type: decimal;
  list-style-position: inside;
}

.ul-nostyle {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.scroll-flex>* {
  overflow-y: auto;
}

.text-align-left {
  text-align: left;
}

.players {
  margin: 0 10px;
  padding: 0px;
  text-align: left;
}

.chat-emphasize {
  font-weight: bolder;
  font-size: 16px;
}

.align-center {
  align-items: center;
}

.flex-center {
  justify-content: center;
}

.chat-container {
  overflow: scroll;
  height: 200px;
}

.player-container {
  overflow: scroll;
  height: 275px;
}

.flex-container {
  display: flex;
}

.flex-grid {
  display: flex;
  flex-wrap: wrap;
}

.border-box {
  box-sizing: border-box;
}

.flex-item {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
}

.flex-2-col {
  flex: 50%;
  height: 400px;
}

.flex-2-col-sidebar {
  flex: 30%;
  height: 400px;
}

.flex-2-col-main {
  flex: 70%;
  height: 400px;
}

.flex-item .item {
  flex: 1 1 auto;
}

.flex-1 {
  flex: 1;
}

.flex-row {
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
}

.flex-column {
  flex-direction: column;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.flex-inline-form-field {
  flex-direction: column;
  display: flex;
  flex-basis: 0;
  flex-grow: 6;
  flex-shrink: 1;
  padding: 5px;
}

.flex-inline-form-button {
  flex-direction: column;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 5px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 800px) {
  .flex-column {
    flex: 100%;
  }
}

.offset-bottom {
  padding-bottom: 15%;
}

.empty-space {
  margin-bottom: 5%;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.card.margin-bottom-30 {
  margin-bottom: 30px;
}

.time {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.question {
  font-size: calc(10px + 4vmin);
  padding: 4%;
}

.answer {
  width: 40%;
  color: rgb(70, 70, 70);
  min-height: 100px;
}

.answer-wrapper {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%
}

.answer input[type="submit"]:disabled {
  background: #c9c9c9;
}

.answer input[type="submit"]:hover:not([disabled]), input[type=submit]:active {
  background: whitesmoke;
}

/** Keep hover on desktop */
@media (hover: hover) and (pointer: fine) {
  .answer input[type="submit"]:hover:not([disabled]), input[type=submit]:active {
    background: #d8d8d8;
  }
}

.answer input[type="submit"] {
  width: 100%;
  height: 100%;
  background: whitesmoke;
  border: 0;
  padding: 0%;
  font-size: 100%;
  color: rgb(70, 70, 70);
  cursor: pointer;
  white-space: normal;
}

.answer input {
  margin: 0;
}

.dark-border {
  border: 1px solid black;
}

.light-background {
  background: whitesmoke;
}

.hidden {
  display: none;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .center-screen {
    width: 96%;
  }

  .offset-phone-addressbar {
    margin-bottom: 18%;
  }

  .box {
    width: 70%;
  }

  .popup {
    width: 70%;
  }

  .flex-2-col {
    flex: 100%;
  }
}

/* Desktop ----------- */
@media screen and (min-device-width: 400px) {
  .card {
    padding: 20px 20px;
    position: relative;
  }

  .card-md-fixed-auto-height {
    height: 375px;
    overflow: none;
  }

  .large-title {
    display: block;
    font-size: 4em;
    font-weight: bold;
    margin: .5vm;
  }

  .center-65 {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
  }

  .chat-group {
    position: absolute;
    bottom: 25px;
    margin: 0 0;
    width: 65%;
  }

  .flex-2-col {
    flex: 100%;
  }
}

/* Smartphones (landscape) ----------- */
@media (orientation: landscape) and (max-height: 600px) {
  .center-screen {
    width: 96%;
  }

  .card-md-fixed-auto-height {
    height: 375px;
    overflow: none;
  }

  .card-slim {
    padding: 1px 30px;
    position: relative;
  }

  .card {
    padding: 1px 30px;
    position: relative;
  }

  .landscape-hidden {
    display: none
  }

  .large-title {
    display: block;
    font-size: 4em;
    font-weight: bold;
    margin: .5vm;
  }

  .center-65 {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }

  .chat-group {
    position: absolute;
    bottom: 15px;
    margin: 0 0;
    width: 58%;
  }

  .flex-2-col {
    flex: 100%;
  }
}

/* Smartphones (portrait) ----------- */
@media (orientation: portrait) and (max-width: 500px) {
  .center-screen {
    width: 80%;
  }

  .center-65 {
    width: 97%;
  }

  .box {
    width: 70%;
  }

  .popup {
    width: 70%;
  }

  .card {
    padding: 5px 15px;
    position: relative;
  }

  .chat-group {
    position: absolute;
    bottom: 15px;
    margin: 0 0;
    width: 83%;
  }

  .large-title {
    display: block;
    font-size: 2.5em;
    font-weight: bold;
    margin: .5vm;
  }

  .flex-2-col {
    flex: 100%;
  }
}

.pd-5 {
  padding: 5px;
}

.pd-5-lr {
  padding: 0 5px;
}

.pd-25 {
  padding: 25px;
}

.md-5 {
  margin: 5px;
}

.margin-15p {
  margin: 15%;
}

.margin-25px {
  margin: 25px;
}

.reset-pm {
  padding: 0px;
  margin: 0px;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.players-wrapper {
  padding: 10px;
  height: 25vh;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
}

.center-with-right-div {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 5px;
  justify-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.center-with-right-div span:nth-child(1) {
  grid-column-start: 2;
}

.center-with-right-div span:nth-child(2) {
  margin-left: auto;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 1;
}

.overlay-visible {
  visibility: visible;
  opacity: 1;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  color: #333;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  font-family: Tahoma, Arial, sans-serif;
}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
}

.popup .close:hover {
  color: #6b6b6b;
}

.popup .content {
  max-height: 30%;
  overflow: auto;
}

.card {
  color: #333;
  background: white;
  border-radius: 3px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  font-size: 16px;
  font-weight: normal;
}

.card h3 {
  text-align: center;
}

.app-blue .card label {
  color: #333;
}

.card .close {
  position: absolute;
  top: 0;
  right: 10px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

form {
  width: 100%;
  margin: 45px auto;
}

.group {
  position: relative;
  margin: 45px 0;
}

.group-compact {
  position: relative;
  margin: 0;
  padding: 0 0 20px 0;
}

textarea {
  resize: none;
}

input, textarea, select {
  background: none;
  color: black;
  font-size: 18px;
  padding: 10px 0 10px 0;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .42);
}

input[type="checkbox"] {
  width: auto;
  display: inline-block;
}

input:not([type='checkbox']):not([type='radio']):focus, textarea:focus, select:focus {
  outline: none;
}

input:not([type='checkbox']):not([type='radio']):focus~label, textarea:focus~label, input:valid~label, input:out-of-range~label, textarea:valid~label, select:valid~label {
  top: -14px;
  font-size: 12px;
  color: #2196f3;
}

input:not([type='checkbox']):not([type='radio']):focus~.bar:before, textarea:focus~.bar:before, select:focus~.bar:before {
  width: 100%;
}

input[type="password"] {
  letter-spacing: 0.3em;
}

.error {
  color: red;
  text-align: left;
  font-size: 12px;
}

input~label {
  color: #c6c6c6;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0px;
  top: 10px;
  transition: 300ms ease all;
}

input[type='checkbox'], input[type='radio'] {
  display: inline-block;
  width: auto;
}

label.pointer-events {
  pointer-events: auto;
  color: #c6c6c6;
  font-size: 16px;
  font-weight: normal;
  left: 0;
  top: auto;
  position: relative;
  transition: none;
  display: inline-block;
}

.bar {
  position: relative;
  display: block;
  width: 100%;
}

.bar:before {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: #2196f3;
  transition: 300ms ease all;
  left: 0%;
}

.btn {
  background: #fff;
  color: #959595;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 3px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.btn:disabled, .btn.btn-submit:disabled {
  background: rgb(238, 238, 238);
  color: #959595;
}

.btn:hover:not([disabled]) {
  color: #8b8b8b;
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12);
}

.btn.btn-link {
  background: #2196f3;
  color: #d3eafd;
}

.btn.btn-link:hover {
  background: #0d8aee;
  color: #deeffd;
}

.btn.btn-submit {
  background: #2196f3;
  color: #ffffff;
}

.btn.btn-submit:hover:not([disabled]) {
  background: #0d8aee;
  color: #deeffd;
}

.btn.btn-cancel {
  background: #eee;
}

.btn.btn-cancel:hover {
  background: #e1e1e1;
  color: #8b8b8b;
}

.btn-box {
  text-align: center;
  margin: 10px 0;
}

.btn-nostyle {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.shake {
  animation-duration: 1s;
  animation-name: shake;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.slidein-right {
  animation-duration: 1s;
  animation-name: slidein-right;
}

@keyframes slidein-right {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.slidein-left {
  animation-duration: 1s;
  animation-name: slidein-left;
}

@keyframes slidein-left {
  from {
    margin-right: 100%;
    width: 300%;
  }

  to {
    margin-right: 0%;
    width: 100%;
  }
}

.slidein-top {
  animation-duration: 1s;
  animation-name: slidein-top;
}

@keyframes slidein-top {
  from {
    margin-top: 100%;
    width: 300%;
  }

  to {
    margin-top: 0%;
    width: 100%;
  }
}

.bouncy {
  animation: bouncy 5s infinite linear;
  position: relative;
}

@keyframes bouncy {
  0% {
    top: 0em
  }

  40% {
    top: 0em
  }

  43% {
    top: -0.9em
  }

  46% {
    top: 0em
  }

  48% {
    top: -0.4em
  }

  50% {
    top: 0em
  }

  100% {
    top: 0em;
  }
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}